.tcell {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.actions {
  height: 50px;
}
.actions > * {
  margin-top: 8px;
}
.pot-row {
  display: none;
}
.pot-card {
  display: block;
  position: relative;
}
.reference {
  color: rgba(0,0,0,0.4);
  text-align: right;
}
@media (min-width: 768px) {
  .pot-row {
    display: flex;
    position: relative;
    margin: 4px;
  }
  .pot-row:hover .row {
    background: #e1e1e1;
    cursor: pointer;
  }
  .pot-row .row {
    width: 100%;
    transition: background-color 0.1s ease-out;
  }
  .pot-card {
    display: none !important;
  }
}
.card-header .requester,
.card-header .reference {
  display: inline-block;
}
.card-header .requester {
  width: 65%;
}
.card-header .reference {
  width: 35%;
}
.type,
.date {
  color: rgba(0,0,0,0.87);
}
.status {
  margin-top: 10px;
}
.assignee {
  position: absolute;
  left: 16px;
}
.type {
  text-transform: capitalize;
}
/*# sourceMappingURL=src/pages/pots/list/pot-entry-card.css.map */