















































































































































.index-list
  padding 0 1rem
  .row-item:first-child
    margin-top 16px

.resource-card
  max-height 82px
  position relative

.top-sheet
  padding 0 15px
  display flex
  justify-content flex-end
  flex-wrap wrap

.content-dropdown
  font-size 18px
  letter-spacing 1px
  text-transform capitalize
.content-type
  text-transform capitalize

.table-head
  display none

@media (min-width: 768px) {
  .index-list {
    padding 0 3rem
    height calc(100vh - 211px)
    overflow auto
    margin auto
  }

  .top-sheet {
    justify-content flex-start
  }

  .content-dropdown {
    font-size 20px
  }

  .table-head {
    display flex
    flex-basis 100%
    width 100%!important
    padding 1rem 3rem
  }

  .dropdown-supplemental {
    display inline-block
    padding 5px
    font-size 1.2rem
  }
}

